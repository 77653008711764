import {FormProvider, useForm} from "react-hook-form";
import InputField from "components/Input";
import SubmitBtn from "components/SubmitBtn/SubmitBtn";
import AuthLayout from "templates/AuthLayout";
import "./style.scss";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {useAdminLoginMutation} from "services/api";
import {selectToken, setUserDetails, setUserToken} from "store/slice/AuthSlice";
import {useDispatch, useSelector} from "react-redux";
import {toaster} from "utils/utils";
const AdminLogin = () => {
  const methods = useForm();
  const navigate = useNavigate();
  const [login, {isLoading}] = useAdminLoginMutation();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  // get redirect url from the query
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("redirectTo");

  const onSubmit = async data => {
    try {
      // call login trigger from rtk query
      const response = await login(data).unwrap();
      // set user details and token in the state
      dispatch(setUserDetails({user: response.data.data}));
      dispatch(setUserToken({token: response.data.token}));
      toaster("success", "Login Successful");
      navigate(search ? search : "/admin");
      // navigate("/dashboard/profile", { replace: true });
    } catch (err) {
      console.log(err);

      if (err.status === "FETCH_ERROR")
        toaster(
          "error",
          "Something went wrong, please check your internet connection and try again"
        );
      else toaster("error", err.data.message);
    }
  };

  if (token) return <Navigate to="/admin" />;
  return (
    <AuthLayout title={"Welcome"} sucMsg={""}>
      <FormProvider {...methods}>
        <form
          className="pd_admin_login"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <InputField
            type="email"
            name="email"
            placeholder="raji@gmail.com"
            id="email"
            label="Email Address"
            errMsg="invalid email input"
          />

          <InputField
            type="password"
            name="password"
            id="password"
            label="Password"
            errMsg="invalid input"
          />

          <SubmitBtn isLoading={isLoading} btnText={"Login"} />
        </form>
      </FormProvider>
    </AuthLayout>
  );
};

export default AdminLogin;
