import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import moment from "moment";
import {formatCurrency} from "utils/utils";
import EmptyResponse from "components/EmptyResponse";

function ActivitiesTab({data}) {
  return (
    <>
      <div className="flex">
        <h3 className="item">Today</h3>
      </div>
      <br />
      {data.length <= 0 && (
        <EmptyResponse message={"You have no activities yet"} />
      )}
      <Timeline position="opposite">
        {data.map((item, i) => (
          <TimelineItem>
            <TimelineOppositeContent
              sx={{
                pl: 0,
                pr: 0,
                flex: "unset",
                width: "70px",
                textAlign: "left",
                fontSize: "0.8rem",
              }}
              color="text.secondary"
            >
              {moment(item.created_at).format("LT")}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              {i < data.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent sx={{pb: 3.5}}>
              <h4>{item.activity_name}</h4>
              <small>amount: {formatCurrency(item.ammount)}</small>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </>
  );
}

export default ActivitiesTab;
