import {FormProvider, useForm} from "react-hook-form";
import InputField from "components/Input";
import {useAdminChangePasswordMutation} from "services/api";
import {toaster} from "utils/utils";
import {LoadingButton} from "@mui/lab";

function Security() {
  const methods = useForm();
  const [addPassword, {isLoading}] = useAdminChangePasswordMutation();
  const onSubmit = async val => {
    try {
      await addPassword(val).unwrap();
      toaster("success", "Successful");
    } catch (err) {
      console.log(err);
      if (err.status === "FETCH_ERROR") {
        toaster("error", "Something went wrong, please try again...");
      } else {
        toaster("error", err.data.message);
      }
    }
  };
  return (
    <div className="security-settings">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex">
            <div className="title">
              <h3>Password</h3>
              <p>Create new password</p>
            </div>
            <div className="form-wrap">
              <div className="form-group-wrap">
                <InputField
                  type="password"
                  name="old_password"
                  label={"Current password"}
                  placeholder="***************"
                />
              </div>
              <div className="form-group-wrap">
                <InputField
                  type="password"
                  name="new_password"
                  label={"New password"}
                  placeholder="***************"
                />
                <InputField
                  type="password"
                  name="confirm_password"
                  label={"Confirm new password"}
                  placeholder="***************"
                />
              </div>
            </div>
          </div>

          <div className="btn-wrap">
            <LoadingButton
              loading={isLoading}
              type="submit"
              color="primary"
              variant={"contained"}
            >
              Save Changes
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default Security;
