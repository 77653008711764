import { Avatar, Stack } from "@mui/material";
import { useState } from "react";
import TableComponent from "components/TableComponent";
import SortSelect from "components/TableComponent/SortSelect";
import { getComparator, stableSort, toaster } from "utils/utils";
// import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "components/Input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  useGetAdminUsersQuery,
  useAdminSuspendUserMutation,
  useGetAdminAllUsersQuery,
} from "services/api";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Users = () => {
  const [selectedMember, setSelectedMember] = useState([]);
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } = useGetAdminUsersQuery(page);
  const { data: allData } = useGetAdminAllUsersQuery(page);
  const [orderBy, setOrderBy] = useState("index");
  const methods = useForm();
  const [suspendUser, { isLoading: isSuspendLoading }] =
    useAdminSuspendUserMutation();
  const suspend = async (type, id) => {
    let body = {
      type,
    };
    try {
      await suspendUser({ credentials: body, id }).unwrap();
      toaster("success", "Action successful");
    } catch (err) {
      console.log(err);
      if (err.status === "FETCH_ERROR") {
        toaster(
          "error",
          "Something went wrong, please check your internet connection and try again"
        );
      } else {
        toaster("error", err.data.message);
      }
    }
  };

  return (
    <div className="pd_home">
      <h2 className="pg-title">Users</h2>
      <Stack
        direction="row"
        spacing={1}
        alignItems="flex-start"
        justifyContent={"space-between"}
      >
        <FormProvider {...methods}>
          <form>
            <InputField
              className={"search-input"}
              name="search"
              type={"search"}
              placeholder="Search by store name or keyword..."
              iconPosition="left"
              iconPlaceholder={
                <SearchOutlinedIcon color="textClr" fontSize="large" />
              }
            />
          </form>
        </FormProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"flex-end"}
        >
          <SortSelect
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            name="feedback_type"
            selectOption={[
              { label: "Default", value: "index" },
              { label: "Name", value: "full_name" },
              { label: "Phone Number", value: "phone_number" },
              "email",
              { label: "Date", value: "created_at" },
            ]}
          />
          {/* <Button variant="contained" startIcon={<OpenInNewOutlinedIcon />}>
            Export
          </Button> */}
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn btn-success"
            table="table-to-xls"
            filename="Users"
            sheet="tablexls"
            buttonText="Export"
          />
        </Stack>
      </Stack>
      <div className="table_download" style={{ display: "none" }}>
        <table id="table-to-xls">
          <thead>
            <th>Full Name</th>
            <th>Email Address</th>
            <th>Phone Numbe</th>
            <th>Links created</th>
            <th>Date joined</th>
          </thead>

          <tbody>
            {allData &&
              allData?.users.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.full_name}</td>
                    <td>{item.email}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.payment_links.length + " Links"}</td>
                    <td>{moment(item.created_at).format("ll")}</td>
                  </tr>
                );
              })}
            <tr></tr>
          </tbody>
        </table>
      </div>
      <TableComponent
        isLoading={isLoading}
        isError={isError}
        error={error}
        page={page}
        setPage={setPage}
        meta={{
          totalPages: data?.totalPages,
          currentPage: data?.currentPage,
          totalCount: data?.totalUsers,
        }}
        headCells={[
          { name: "Full Name", key: "full_name" },
          { name: "Email Address", key: "email" },
          { name: "Phone Number", key: "number" },
          { name: "Links created", key: "link" },
          { name: "Date joined", key: "date" },
        ]}
        tableData={stableSort(
          data?.users || [],
          getComparator("asc", orderBy)
        ).map((item, i) => ({
          full_name: (
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                src={item.profile_image}
                sx={{
                  width: "1.8rem",
                  height: "1.8rem",
                }}
              />
              <span>{item.full_name}</span>
            </Stack>
          ),
          number: item.phone_number,
          email: item.email,
          link: item.payment_links.length + " Links",
          actions: (
            <>
              <Link to={`${item.id}`} className="btn-noBg">
                View Profile
              </Link>
              <LoadingButton
                onClick={() => {
                  suspend(
                    item.status === "disabled" ? "activate" : "suspend",
                    item.id
                  );
                }}
                color="redClr"
                disabled={item.status === "disabled" ? true : false}
                loading={isSuspendLoading}
              >
                Suspend account
              </LoadingButton>
            </>
          ),
          date: moment(item.created_at).format("ll"),
          id: item.id,
        }))}
        selected={selectedMember}
        onSelect={(selectedIds) => setSelectedMember(selectedIds)}
      />
    </div>
  );
};

export default Users;
