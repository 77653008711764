import {Avatar, FormControlLabel, Switch} from "@mui/material";
import {useState} from "react";
import TabsHeader from "components/TabsHeader";
import PaperBackBtn from "../../../templates/backBtnPaper/index.";
import "./style.scss";
import TransactionsTab from "./Tabs/TransactionsTab";
import UserDetailsTab from "./Tabs/UserDetailsTab";
import ActivitiesTab from "./Tabs/ActivitiesTab";
import {useParams} from "react-router-dom";
import {
  useAdminSuspendUserMutation,
  useGetAdminSingleUserQuery,
} from "services/api";
import Loader from "components/Loader";
import ErrorMsg from "components/ErrorMsg";
import {toaster} from "utils/utils";
function EachUser() {
  const [activeTab, setActiveTab] = useState("Personal information");
  const TABS = ["Personal information", "Transactions", "Activites"];

  const {id} = useParams();
  const {data, isLoading, isError, error} = useGetAdminSingleUserQuery(id);

  const [suspendUser] = useAdminSuspendUserMutation();
  const handleChange = async type => {
    let body = {
      type,
    };
    try {
      await suspendUser({credentials: body, id}).unwrap();
      toaster("success", "Action successful");
    } catch (err) {
      console.log(err);
      if (err.status === "FETCH_ERROR") {
        toaster(
          "error",
          "Something went wrong, please check your internet connection and try again"
        );
      } else {
        toaster("error", err.data.message);
      }
    }
  };

  return (
    <PaperBackBtn>
      {isLoading && <Loader />}
      {isError && <ErrorMsg error={error} />}
      {data && (
        <div className="each-user-pg">
          <div className="profile-header">
            <div className="left">
              <Avatar
                src={data.profile_image}
                sx={{width: 70, height: 70, mr: 2}}
              />
              <div>
                <h3>{data.full_name}</h3>
                {/* <div className="flex">
                  <StarRating value={3} sx={{ pt: 0.5, mr: 1 }} />
                  <small className="clr-greyClr">(4.5 star rating)</small>
                </div> */}
              </div>
            </div>
            <FormControlLabel
              className="switch-wrap"
              control={
                <Switch
                  className="switch"
                  size="small"
                  checked={data.status === "disabled" ? false : true}
                  onChange={() => {
                    handleChange(
                      data.status === "disabled" ? "activate" : "suspend"
                    );
                  }}
                />
              }
              labelPlacement="end"
              label={`${
                data.status === "disabled"
                  ? "Activate account"
                  : "Suspend account"
              }`}
            />
          </div>
          <br />
          <TabsHeader
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={TABS}
          />

          {activeTab === TABS[0] && <UserDetailsTab data={data} />}
          {activeTab === TABS[1] && (
            <TransactionsTab transactions={data?.transactions || []} />
          )}
          {activeTab === TABS[2] && (
            <ActivitiesTab data={data?.activities || []} />
          )}
        </div>
      )}
    </PaperBackBtn>
  );
}

export default EachUser;
