import {useState} from "react";
import TabsHeader from "components/TabsHeader";
import "./style.scss";
import General from "./Tabs/General";
import Security from "./Tabs/Security";

function AdminSettings() {
  const TABS = ["General", "Security & login"];
  const [activeTab, setActiveTab] = useState("General");

  return (
    <div className="pd_settings">
      <h2 className="pg-title">Settings</h2>
      <TabsHeader
        tabs={TABS}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="tabs-wrapper">
        {activeTab === TABS[0] && <General />}
        {activeTab === TABS[1] && <Security />}
      </div>
    </div>
  );
}

export default AdminSettings;
