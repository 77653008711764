import "./style.scss";
const EachBox = ({ icon, title, border, amount }) => {
  return (
    <div
      className={`eachBox ${border === "right" ? "border" : ""} ${
        border === "left" ? "left" : ""
      }`}
    >
      <div className="left">{icon}</div>
      <div className="right">
        <p className="title">{title}</p>
        <div className="bottom">
          <p className="number">{amount}</p>
        </div>
      </div>
    </div>
  );
};

export default EachBox;
