import ErrorMsg from "components/ErrorMsg";
import Loader from "components/Loader";
import NavBar from "components/Nav";
import "./style.scss";
function MainLayout({
  children,
  navChildren,
  bg = "bg-secondary",
  loading,
  error,
  isError,
}) {
  return (
    <div className={`pg_verify ${bg}`}>
      <div className={`nav-wrap ${bg === "bg-whiteClr" ? "border-nav" : ""}`}>
        <NavBar />
        <div className="bg-whiteClr">
          <div className="inner">{navChildren}</div>
        </div>
      </div>
      <div className="inner">
        {loading ? <Loader /> : isError ? <ErrorMsg error={error} /> : children}
      </div>
    </div>
  );
}

export default MainLayout;
