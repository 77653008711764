import { Link } from "@mui/material";

function UserDetailsTab({ data }) {
  return (
    <div className="details-wrap">
      <div className="details">
        <p className="title">Name</p>
        <p className="text">{data.full_name}</p>
      </div>
      {/* <div className="details">
        <p className="title">Date of birth</p>
        <p className="text">Dec 30, 1995</p>
      </div> */}
      <div className="details">
        <p className="title">Email</p>
        <Link className="text">{data.email}</Link>
      </div>
      {/* <div className="details">
        <p className="title">VerifyLink</p>
        <Link className="text">verifyNG.com/Darlene</Link>
      </div> */}
      <div className="details">
        <p className="title">Phone number</p>
        <p className="text">{data.phone_number}</p>
      </div>
      <div className="details">
        <p className="title">Status</p>
        <p className="text">{data.status}</p>
      </div>
    </div>
  );
}

export default UserDetailsTab;
