import { Button } from "@mui/material";
import "./style.scss";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as UserIcon } from "assets/icons/userIcon.svg";
import { Link } from "react-router-dom";
import useCopy from "hooks/useCopy";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import { toCurrency } from "utils/utils";
export const status_key_pair = {
  pending: "yellowClr",
  cancelled: "redClr",
  declined: "redClr",
  paid: "greenClr",
  shipped: "purpleClr",
  processed: "greenClr",
  disputed: "grey300",
  approved: "greenApproval",
};

function VerifyCard({
  data: {
    product_title,
    id,
    buyer_emails,
    created_at,
    status,
    price,
    link_color,
    payment_link,
    is_seller,
  },
  data,
}) {
  const { copied, copyAction } = useCopy(
    `${window.location.origin}/link/${payment_link}`
  );
  // pending,paid,shipped,processed,disputed,cancelled
  return (
    <article className="card">
      <div
        className="card_color_bar"
        style={{ backgroundColor: `${link_color}` }}
      />
      <Link to={`/link/${payment_link}`} className="card_body">
        <div className="setting_icon">
          <div className="avatar-group">
            <UserIcon />
            {buyer_emails.length > 1 && (
              <div className="more">
                <span>+ {buyer_emails.length - 1}</span>
              </div>
            )}
          </div>
        </div>
        <p className="bold title">{product_title}</p>
        <p>
          {is_seller ? "Seller" : "Buyer"} - {moment(created_at).format("L")}
        </p>
        <div className="flex">
          {/* <Chip label={status} color={status_key_pair[status.toLowerCase()]} /> */}
          <p className="bold">{toCurrency(price)}</p>
        </div>
      </Link>
      <div className="card_footer">
        <Button
          color={copied ? "greenClr" : "primary"}
          onClick={() => copyAction()}
          startIcon={copied ? <CheckIcon /> : <CopyIcon />}
        >
          {copied ? "Copied" : "Copy Link"}
        </Button>
      </div>
    </article>
  );
}

export default VerifyCard;
