import {Avatar, Chip} from "@mui/material";
import EmptyResponse from "components/EmptyResponse";
import {status_key_pair} from "components/VerifyCard";
import {toCurrency} from "utils/utils";

const RecentActivities = ({data}) => {
  return (
    <div className="pd_recent">
      <table>
        <tbody>
          {!data.length ? (
            <tr>
              <td>
                <EmptyResponse message={"Empty response"} />
              </td>
            </tr>
          ) : (
            data.map(item => {
              return (
                <tr key={item.id}>
                  <td>
                    {" "}
                    <div className="flex">
                      <Avatar
                        src={item.seller.profile_image_cloudinary_id}
                        sx={{
                          width: "1.8rem",
                          height: "1.8rem",
                        }}
                      />
                      <p className="name">{item.seller.full_name}</p>
                    </div>
                  </td>
                  <td>
                    <a
                      href={`${window.location.origin}/link/${item.payment_link}`}
                    >
                      {window.location.origin}/link/{item.payment_link}
                    </a>
                  </td>
                  <td>{toCurrency(item.price)}</td>
                  <td>
                    <Chip
                      label={item.status}
                      color={
                        item.status
                          ? status_key_pair[item.status.toLowerCase()]
                          : "yellowClr"
                      }
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default RecentActivities;
