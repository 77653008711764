import { Avatar } from "@mui/material";
import moment from "moment";
import truncateString from "utils/trunc";

import "./style.scss";
const MessageBox = ({
  id,
  active,
  time,
  setActive,
  setId,
  member,
  latestMessage,
  skipper,
}) => {
  // const [sender, setSender] = useState(null);
  // useEffect(() => {
  //   const sendernew = members
  //     ? members.filter(item => {
  //         return item._id !== "1232135";
  //       })
  //     : "";

  //   setSender(sendernew[0]);
  // }, [members]);
  return (
    <>
      {member ? (
        <div
          animate="visible"
          initial="hidden"
          onClick={() => {
            setActive(id);
            // setId(members.id, members[1]._id);
            skipper();
          }}
          className={`message_box ${active ? "active" : ""}`}
        >
          <Avatar
            alt={member?.full_name}
            src={member?.profile_image}
            sx={{ width: 40, height: 40 }}
          />
          <div className="textBox">
            <div className="nameSide">
              <p className="name">{`${member?.full_name} `}</p>
              <p className="time">{moment(time).fromNow()}</p>
            </div>
            <div className="messageSide">
              <p className="message">
                {truncateString(latestMessage || "", 60)}
              </p>
              {/* <p className="numberCircle">{numberOfNew}</p> */}
            </div>
          </div>
        </div>
      ) : (
        <p></p>
      )}
    </>
  );
};

export default MessageBox;
