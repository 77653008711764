import {useEffect, useRef, useState} from "react";
import ChatBox from "components/ChatBox";
import LoadingHead from "components/LoaderHead/loaderhead";
// import {ReactComponent as Fileicon} from "assets/icons/fileIcon.svg";

import "./style.scss";
import {useGetAdminSingleDisputesQuery} from "services/api";
import {useParams} from "react-router-dom";
import PaperBackBtn from "templates/backBtnPaper/index.";
import ErrorMsg from "components/ErrorMsg";
import Loader from "components/Loader";
import io from "socket.io-client";
const AdminChat = () => {
  const [activeMsg, setActiveMsg] = useState("");
  const [message, setMessage] = useState([]);
  const {id} = useParams();

  // get single user chat

  const {
    data: chat = null,
    isLoading,
    isError,
    error,
  } = useGetAdminSingleDisputesQuery(id);
  useEffect(() => {
    // setMessage(chat.data[0].messages);
    if (chat) {
      setMessage(chat.data.messages);
    }
  }, [chat]);

  const socketRef = useRef(null);
  useEffect(() => {
    if (id) {
      socketRef.current = io("https://paay-be.herokuapp.com", {
        query: {conversationId: id},
        transports: ["websocket"],
      });

      socketRef.current.on("connect", () => {});

      socketRef.current.on(`dispute_${id}`, newMsg => {
        setMessage(prev => {
          return [
            ...prev,
            {
              created_at: newMsg.created_at,
              files: newMsg.files,
              id: newMsg.id,
              isAdmin: newMsg.isAdmin,
              message: newMsg.message,
              sender_id: newMsg.sender_id,
              sender_name: newMsg.sender_name,
              updated_at: newMsg.updated_at,
            },
          ];
        });
      });
      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [id]);

  return (
    <PaperBackBtn style={{padding: 0, margin: 0}}>
      {isError && <ErrorMsg error={error} />}
      {isLoading ? (
        <Loader />
      ) : (
        <div style={{padding: 0, height: "100%"}} className="pd-chat">
          <LoadingHead status={false} />
          <div className="whiteContainer">
            <div
              style={{width: "100%"}}
              className={`chatBoxDiv ${activeMsg ? "active" : ""}`}
            >
              <ChatBox
                setActive={setActiveMsg}
                active={activeMsg}
                isError={isError}
                currentMsg={{member: chat && chat.data, isActive: true}}
                messages={message}
                loading={isLoading}
                idAdmin={id}
                isAdmin={true}
              />
            </div>
          </div>
        </div>
      )}
    </PaperBackBtn>
  );
};

export default AdminChat;
