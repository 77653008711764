import { Chip, Stack } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import TableComponent from "components/TableComponent";
import SortSelect from "components/TableComponent/SortSelect";
import { getComparator, stableSort } from "utils/utils";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "components/Input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useGetAdminDisputesQuery } from "services/api";
import moment from "moment";

const Disputes = () => {
  const [selectedMember, setSelectedMember] = useState([]);
  const [orderBy, setOrderBy] = useState("index");
  const methods = useForm();
  const [page, setPage] = useState(1);
  const {
    data = null,
    isLoading,
    isError,
    error,
  } = useGetAdminDisputesQuery(page);
  return (
    <div className="pd_home">
      <h2 className="pg-title">Dispute Center</h2>
      <Stack
        direction="row"
        spacing={1}
        alignItems="flex-start"
        justifyContent={"space-between"}
      >
        <FormProvider {...methods}>
          <form>
            <InputField
              className={"search-input"}
              name="search"
              type={"search"}
              placeholder="Search by store name or keyword..."
              iconPosition="left"
              iconPlaceholder={
                <SearchOutlinedIcon color="textClr" fontSize="large" />
              }
            />
          </form>
        </FormProvider>
        <SortSelect
          value={orderBy}
          onChange={(e) => setOrderBy(e.target.value)}
          name="feedback_type"
          selectOption={[
            { label: "Default", value: "index" },
            { label: "Seller", value: "seller_email" },
            { label: "Buyer", value: "buyer_email" },
            { label: "Reason", value: "title" },
            { label: "Ticket ID", value: "ticket_id" },
            { label: "Date", value: "updated_at" },
          ]}
        />
      </Stack>
      <TableComponent
        isLoading={isLoading}
        isError={isError}
        error={error}
        page={page}
        setPage={setPage}
        meta={{
          totalPages: data?.data.totalPages,
          currentPage: data?.data.currentPage,
          totalCount: data?.data.totalDisputes,
        }}
        headCells={[
          { name: "Ticket ID", key: "id" },
          { name: "Buyer Email", key: "email" },
          { name: "Seller Email", key: "seller_email" },
          { name: "Status", key: "status" },
          { name: "Reason", key: "title" },
          { name: "Date created", key: "updated_at" },
        ]}
        tableData={stableSort(
          data ? data.data.disputes : [],
          getComparator("asc", orderBy)
        ).map((item, i) => ({
          email: item.buyer_email,
          seller_email: item.seller_email,
          status: (
            <Chip
              color={`${
                item.status === "opened"
                  ? "yellowClr"
                  : item.status === "declined"
                  ? "redClr"
                  : "greenClr"
              }`}
              label={item.status}
            />
          ),
          title: item.title,
          actions: (
            <>
              <Link to={`${item.id}`} className="btn-noBg">
                View Dispute
              </Link>
            </>
          ),

          updated_at: moment(item.updated_at).format("ll"),
          id: item.ticket_id,
        }))}
        selected={selectedMember}
        onSelect={(selectedIds) => setSelectedMember(selectedIds)}
      />
    </div>
  );
};

export default Disputes;
