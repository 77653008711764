import moment from "moment";
import "./style.scss";
import {FormProvider, useForm} from "react-hook-form";
// import InputField from  "../input";
import {ReactComponent as SendIcon} from "assets/icons/sendIcon.svg";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import NoProduct from "../NoProduct";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import InputField from "../Input";
import {useEffect, useRef, useState} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Loader from "../Loader/index";
import {Avatar, Button, Chip, IconButton} from "@mui/material";
import SubmitBtn from "../SubmitBtn/SubmitBtn";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Modal from "../Modal";
import DropDownWrapper from "../DropDownWrapper";
import {
  useResolveDisputeMutation,
  useSendAdminChatMutation,
  useSendUserChatMutation,
} from "services/api";
import {toaster} from "utils/utils";
import {useGetUser} from "hooks/getUserHook";
import {LoadingButton} from "@mui/lab";
const ChatBox = ({
  currentMsg,
  messages,
  loading,
  isError,
  setActive,
  active,
  isAdmin = false,
  idAdmin,
}) => {
  const methods = useForm();
  const [openModal, setOpenModal] = useState(false);
  const [src, setSrc] = useState("");
  const [sender, setSender] = useState(null);
  const {user} = useGetUser();

  useEffect(() => {
    if (currentMsg && currentMsg.member) {
      if (currentMsg.member.buyer_email === user.email) {
        setSender(currentMsg.member.seller);
      } else if (currentMsg.member.seller_email === user.email) {
        setSender(currentMsg.member.buyer);
      }
    }
  }, [currentMsg, user.email]);

  const messagesEndRef = useRef(null);
  const chatsRef = useRef();

  useEffect(() => {
    if (chatsRef.current && messagesEndRef.current)
      chatsRef.current.scroll({
        top: messagesEndRef.current.offsetTop,
        behavior: "smooth",
      });
  }, []);

  const [sendChat, {isLoading}] = useSendUserChatMutation();
  const [sendChatAdmin, {isLoading: load}] = useSendAdminChatMutation();
  const [resolveDispute, {isLoading: resolveLoad}] =
    useResolveDisputeMutation();
  const [uploadLoad, setUploadLoad] = useState(false);
  const onSubmit = async vals => {
    if (!isAdmin) {
      try {
        await sendChat({body: vals, id: active}).unwrap();
        methods.reset();
      } catch (err) {
        console.log(err);
        if (err.status === "FETCH_ERROR") {
          toaster(
            "error",
            "Something went wrong, please check your internet connection and try again"
          );
        } else {
          toaster("error", err.data.message);
        }
      }
    } else {
      try {
        await sendChatAdmin({body: vals, id: idAdmin}).unwrap();
        methods.reset();
      } catch (err) {
        console.log(err);
        if (err.status === "FETCH_ERROR") {
          toaster(
            "error",
            "Something went wrong, please check your internet connection and try again"
          );
        } else {
          toaster("error", err.data.message);
        }
      }
    }
  };

  const uploader = async img => {
    const formdata = new FormData();

    formdata.append("file", img, "avatar.jpg");

    setUploadLoad(true);

    if (!isAdmin) {
      try {
        await sendChat({body: formdata, id: active}).unwrap();
        methods.reset();
        setUploadLoad(false);
      } catch (err) {
        console.log(err);
        setUploadLoad(false);
        if (err.status === "FETCH_ERROR") {
          toaster(
            "error",
            "Something went wrong, please check your internet connection and try again"
          );
        } else {
          toaster("error", err.data.message);
        }
      }
    } else {
      try {
        await sendChatAdmin({body: formdata, id: idAdmin}).unwrap();
        methods.reset();
        setUploadLoad(false);
      } catch (err) {
        console.log(err);
        setUploadLoad(false);
        if (err.status === "FETCH_ERROR") {
          toaster(
            "error",
            "Something went wrong, please check your internet connection and try again"
          );
        } else {
          toaster("error", err.data.message);
        }
      }
    }
  };

  const resolve = async type => {
    const payload = {
      type,
    };
    try {
      await resolveDispute({body: payload, id: idAdmin}).unwrap();
      toaster("success", "Resolved successfully");
    } catch (err) {
      console.log(err);
      if (err.status === "FETCH_ERROR") {
        toaster(
          "error",
          "Something went wrong, please check your internet connection and try again"
        );
      } else {
        toaster("error", err.data.message);
      }
    }
  };

  return (
    <>
      {currentMsg && currentMsg.member ? (
        loading ? (
          <Loader />
        ) : (
          !isError && (
            <div className="chatBox">
              <div className="chatDetails">
                <div className="userDetail">
                  <IconButton
                    sx={{p: 0}}
                    className="back-btn"
                    onClick={() => setActive(false)}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                  <Avatar
                    alt={"user"}
                    src={sender && sender.profile_image}
                    sx={{width: 31, height: 31}}
                  />
                  <div className="textPart">
                    <p className="name">
                      {/* {sender ? `${sender.full_name} ` : "N/A"} */}
                      Product name:{" "}
                      {currentMsg && currentMsg.member.product_name}
                    </p>
                    <p className="userActive">
                      Transaction ID :{" "}
                      {currentMsg && currentMsg.member.transaction.id}
                    </p>
                    {currentMsg && (
                      <p className="userActive">
                        Reason : {currentMsg.member.title}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <Chip
                    color={`${
                      currentMsg.member.status === "opened"
                        ? "yellowClr"
                        : "greenClr"
                    }`}
                    label={
                      currentMsg.member.status === "opened"
                        ? "Open"
                        : "Resolved"
                    }
                  />
                  {isAdmin && currentMsg.member.status === "opened" && (
                    <DropDownWrapper
                      className={"more"}
                      action={
                        <IconButton>
                          <MoreVertIcon />
                        </IconButton>
                      }
                    >
                      <LoadingButton
                        loading={resolveLoad}
                        onClick={() => {
                          resolve("approve");
                        }}
                      >
                        Approve
                      </LoadingButton>

                      <LoadingButton
                        loading={resolveLoad}
                        onClick={() => {
                          resolve("decline");
                        }}
                      >
                        Decline
                      </LoadingButton>
                    </DropDownWrapper>
                  )}
                </div>
              </div>
              <div className="line"></div>
              <div ref={chatsRef} className="allMessageBox">
                {messages && !messages.length ? (
                  <NoProduct msg="No Message...">
                    <SpeakerNotesOffIcon />
                  </NoProduct>
                ) : (
                  messages.map(item => {
                    return (
                      <div key={item.id}>
                        <div
                          animate="visible"
                          initial="hidden"
                          className={`eachMsg ${
                            item.sender_id === user.id ? "left" : ""
                          }`}
                          key={item.id}
                        >
                          {item.files && (
                            <img
                              src={item.files}
                              style={{width: "150px"}}
                              alt="chat"
                              onClick={() => {
                                setSrc(item.files);
                                setOpenModal(true);
                              }}
                            />
                          )}

                          {item.message && (
                            <p className="msg">{item.message}</p>
                          )}
                          <p className="time">
                            <span className="name">
                              {item.isAdmin ? "Admin" : item.sender_name}
                            </span>
                            <span className="showtime">
                              {moment(item.updated_at).format("LT")}{" "}
                            </span>
                          </p>
                        </div>
                      </div>
                    );
                  })
                )}
                <div ref={messagesEndRef}></div>
              </div>

              {/* chat footer */}
              <div className="chat-footer ">
                {currentMsg && currentMsg.member.status !== "resolved" && (
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <InputField
                        showErr={false}
                        type="text"
                        name="message"
                        placeholder="Type a Message..."
                        id="message"
                      />

                      <input
                        onChange={e => {
                          uploader(e.target.files[0]);
                        }}
                        type="file"
                        name="url"
                        hidden
                        id="url"
                      />
                      <IconButton
                        component={"label"}
                        htmlFor="url"
                        sx={{mx: 1}}
                      >
                        <AttachFileIcon style={{transform: "rotate(40deg"}} />
                      </IconButton>
                      <SubmitBtn
                        isLoading={isLoading || load || uploadLoad}
                        className="sendBtn"
                        btnText={
                          <>
                            <span>Send</span>
                            <SendIcon className="sendIcon" />
                          </>
                        }
                      />
                    </form>
                  </FormProvider>
                )}
              </div>
            </div>
          )
        )
      ) : (
        <NoProduct msg="No Chats Selected...">
          <SpeakerNotesOffIcon />
        </NoProduct>
      )}
      <Modal
        otherClasses="image-modal"
        openModal={openModal}
        hideCancelBtn={true}
        closeModal={() => setOpenModal(!openModal)}
      >
        <img src={src} alt={src} />
        <Button
          disableElevation
          variant="contained"
          href={src.split("upload/").join("upload/fl_attachment/")}
          download
          sx={{width: "100%", my: 2}}
          endIcon={<FileDownloadIcon color="white" />}
        >
          <span className="clr-white">Download Image</span>
        </Button>
      </Modal>
    </>
  );
};

export default ChatBox;
