import {Link, useMatch} from "react-router-dom";
import {ReactComponent as Home} from "assets/icons/sidebar/home.svg";
import {ReactComponent as Tran} from "assets/icons/sidebar/transaction.svg";
import {ReactComponent as Users} from "assets/icons/sidebar/user.svg";
import {ReactComponent as Dispute} from "assets/icons/sidebar/dispute.svg";
import {ReactComponent as Role} from "assets/icons/sidebar/role.svg";
import {ReactComponent as Setting} from "assets/icons/sidebar/setting.svg";

import "./style.scss";
function CustomLink({children, to, ...props}) {
  let match = useMatch(to !== "" ? `/admin/` + to + "/*" : "/admin");
  return (
    <Link className={`item ${match ? "active" : ""}`} to={to} {...props}>
      {children}
    </Link>
  );
}
const SideBar = () => {
  return (
    <div className="pd_sidebar">
      <div className="inner">
        <div className="linkSide">
          <CustomLink to="">
            <Home className="iconSide" />
            <p>Home</p>
          </CustomLink>

          <CustomLink to="transactions">
            <Tran className="iconSide" />
            <p>Transaction</p>
          </CustomLink>
          <CustomLink to="users">
            <Users className="iconSide" />
            <p>Users</p>
          </CustomLink>

          <CustomLink to="disputes">
            <Dispute className="iconSide" />
            <p>Disputes</p>
          </CustomLink>
          <CustomLink to="roles">
            <Role className="iconSide" />
            <p>Roles</p>
          </CustomLink>

          <CustomLink to="settings">
            <Setting className="iconSide" />
            <p>Setting</p>
          </CustomLink>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
