import { Button, Stack } from "@mui/material";
import { useState } from "react";

import SortSelect from "components/TableComponent/SortSelect";
import AddIcon from "@mui/icons-material/Add";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "components/Input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TabsHeader from "components/TabsHeader";
import Modal from "components/Modal";
import RolesTable from "./Tabs/RolesTable";
import AdminsTable from "./Tabs/AdminsTable";
import SelectField from "components/Input/SelectField";
import { useAddAdminMutation, useGeRolesQuery } from "services/api";
import { toaster } from "utils/utils";
import { LoadingButton } from "@mui/lab";
const Roles = () => {
  const [orderBy, setOrderBy] = useState("index");
  const methods = useForm();
  const methods2 = useForm();

  const [activeTab, setActiveTab] = useState("Roles");
  const [openModal, setOpenModal] = useState(false);
  const { data } = useGeRolesQuery(1);
  // const [addRole] = useAddRoleMutation();
  const [addAmin, { isLoading: adminLoading }] = useAddAdminMutation();

  // const createRole = async (val) => {
  //   try {
  //     await addRole(val).unwrap();

  //     toaster("success", "Successful");
  //   } catch (err) {
  //     console.log(err);
  //     if (err.status === "FETCH_ERROR") {
  //       toaster("error", "Something went wrong, please try again...");
  //     } else {
  //       toaster("error", err.data.message);
  //     }
  //   }
  // };
  const createAdmin = async (val) => {
    try {
      await addAmin(val).unwrap();
      toaster("success", "Successful");
    } catch (err) {
      console.log(err);
      if (err.status === "FETCH_ERROR") {
        toaster("error", "Something went wrong, please try again...");
      } else {
        toaster("error", err.data.message);
      }
    }
  };

  return (
    <div className="pd_home">
      <h2 className="pg-title">Role administration</h2>
      <Stack
        direction="row"
        spacing={1}
        alignItems="flex-start"
        justifyContent={"space-between"}
      >
        <FormProvider {...methods}>
          <form>
            <InputField
              className={"search-input admin-search"}
              name="search"
              type={"search"}
              placeholder="Search by store name or keyword..."
              iconPosition="left"
              iconPlaceholder={
                <SearchOutlinedIcon color="textClr" fontSize="large" />
              }
            />
          </form>
        </FormProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="flex-start"
          justifyContent={"flex-end"}
        >
          <SortSelect
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            name="feedback_type"
            selectOption={
              activeTab === "Roles"
                ? [
                    { label: "Default", value: "index" },
                    "title",
                    "acronym",
                    { label: "Date", value: "created_at" },
                  ]
                : [
                    { label: "Default", value: "index" },
                    { label: "Name", value: "full_name" },
                    "email",
                    "role",
                    { label: "Date", value: "created_at" },
                  ]
            }
          />
          <Button
            onClick={() => setOpenModal(true)}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add new
          </Button>
        </Stack>
      </Stack>
      <TabsHeader
        tabs={["Roles", "Admin"]}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      {activeTab === "Roles" && <RolesTable orderBy={orderBy} />}
      {activeTab === "Admin" && <AdminsTable orderBy={orderBy} />}

      <Modal
        closeModal={() => setOpenModal(false)}
        openModal={openModal}
        title="Add New Admin"
      >
        <FormProvider {...methods2}>
          <form onSubmit={methods2.handleSubmit(createAdmin)}>
            <InputField
              label={"Full name"}
              name="full_name"
              placeholder="Full name"
            />

            <InputField
              label={"Email"}
              name="email"
              type="email"
              placeholder="tim.jennings@example.com"
            />
            <SelectField
              label={"Role"}
              name="role_id"
              placeholder="Select Role"
              selectOption={
                data
                  ? data.roles.map((item) => {
                      return {
                        value: item.id,
                        label: item.title,
                      };
                    })
                  : []
              }
            />
            <InputField
              type="password"
              name="password"
              id="password"
              label="Password"
              errMsg="invalid input"
            />
            <div className="btn-wrap">
              <LoadingButton
                loading={adminLoading}
                type="submit"
                color="primary"
                variant={"contained"}
              >
                Add Admin
              </LoadingButton>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </div>
  );
};

export default Roles;
