import {FormProvider, useForm} from "react-hook-form";
import DpUpload from "components/DpUpload";
import InputField from "components/Input";
import SelectField from "components/Input/SelectField";
import {
  useAdminChangeDetailsMutation,
  useGetAdminDetailsQuery,
} from "services/api";
import {toaster} from "utils/utils";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {setUserDetails} from "store/slice/AuthSlice";
import {useDispatch} from "react-redux";

function General() {
  const methods = useForm();
  const dispatch = useDispatch();

  const [changeDetails, {isLoading}] = useAdminChangeDetailsMutation();
  const {data = null} = useGetAdminDetailsQuery();
  const [imgUrl, setImgUrl] = useState("");
  const [img, setImg] = useState("");

  useEffect(() => {
    if (data) {
      dispatch(setUserDetails({user: data.data}));
    }
  }, [data, dispatch]);

  const onSubmit = async vals => {
    console.log(vals);
    const formdata = new FormData();

    formdata.append("full_name", vals.full_name);
    formdata.append("country", vals.country);
    formdata.append("phone_number", vals.phone_number);
    formdata.append("gender", vals.gender);
    if (img) {
      formdata.append("file", img, "avatar.jpg");
    }
    try {
      await changeDetails(formdata).unwrap();
      toaster("success", "Successful");
    } catch (err) {
      console.log(err);
      if (err.status === "FETCH_ERROR") {
        toaster("error", "Something went wrong, please try again...");
      } else {
        toaster("error", err.data.message);
      }
    }
  };

  useEffect(() => {
    if (data) {
      methods.setValue("full_name", data.data.full_name);
      methods.setValue("country", data.data.country);
      methods.setValue("phone_number", data.data.phone_number);
      methods.setValue("gender", data.data.gender);
    }
  }, [data, methods]);

  return (
    <div className="general-settings">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DpUpload
            imgUrl={imgUrl ? imgUrl : data && data.data.profile_image}
            setImgUrl={setImgUrl}
            setImg={setImg}
            name="avatar"
            defaultImg="/images/avatar.png"
          />
          <br />
          <div className="flex">
            <InputField
              type="text"
              name="full_name"
              label={"Full name"}
              placeholder="John Doe"
            />
            {/* <Button type="button">Edit</Button> */}
          </div>

          <div className="flex">
            <InputField
              type="tel"
              name="phone_number"
              label={"Phone number"}
              placeholder="08011223345"
            />
            {/* <Button type="button">Edit</Button> */}
          </div>

          <div className="flex">
            <InputField
              type="text"
              name="country"
              label={"Country"}
              placeholder="Nigeria"
            />
            {/* <Button type="button">Edit</Button> */}
          </div>
          <div className="flex">
            <SelectField
              name="gender"
              label={"Gender"}
              placeholder="Select Gender"
              selectOption={["Male", "Female", "Prefer not to say"]}
            />
            {/* <Button type="button">Edit</Button> */}
          </div>
          <br />
          <div className="btn-wrap">
            <LoadingButton
              loading={isLoading}
              type="submit"
              color="primary"
              variant={"contained"}
            >
              Save Changes
            </LoadingButton>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default General;
