import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import EnhancedTableHead from "../EnhancedTableHead";
import LoadingTable from "../loadingTable";
import ErrorMsg from "../ErrorMsg";
import TablePagination from "../TablePagination";
import "./style.scss";
import {Checkbox, IconButton} from "@mui/material";
import DropDownWrapper from "../DropDownWrapper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EmptyResponse from "components/EmptyResponse";

const headCellTest = [
  {
    key: "name",
    name: "Full Name",
  },
  {
    key: "email",
    name: "Email Address",
  },
  {
    key: "number",
    name: "Phone Number",
  },
  {
    key: "links",
    name: "Links created",
  },
  {
    key: "date",
    name: "Date joined",
  },
];

export default function TableComponent({
  tableData = Array(7)
    .fill("")
    .map((_, i) => ({
      name: "Jacob Jones",
      number: "(405) 555-0128",
      email: "deanna.curtis@example.com",
      role: "01/01/2020",
      action: "",
      id: `row_${i}`,
    })),
  isLoading,
  isError,
  error,
  headCells = headCellTest,
  onSelect,
  selected,
  isPage = true,
  meta,
  page,
  setPage,
  type,
}) {
  const toggleSelectAll = () => {
    if (selected.length !== tableData.length)
      onSelect(tableData.map((row, rowIndex) => row.id));
    else onSelect([]);
  };

  const toggleSelect = id => {
    if (!selected.includes(id)) onSelect([...selected, id]);
    else onSelect(selected.filter(item => item !== id));
  };

  if (isLoading) {
    return <LoadingTable />;
  }
  if (isError) {
    return <ErrorMsg error={error} />;
  }

  return (
    <Box
      className={`table ${type === "outline" ? "outline" : ""}`}
      sx={{width: "100%"}}
    >
      <TableContainer className="paper">
        <Table sx={{minWidth: 750}} aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={toggleSelectAll}
            rowCount={tableData.length}
            headCells={headCells}
          />
          <TableBody>
            {tableData.length <= 0 && (
              <TableRow>
                <TableCell colSpan={headCells.length + 1}>
                  <EmptyResponse message={"No record found"} />
                </TableCell>
              </TableRow>
            )}
            {tableData.map((row, rowIndex) => {
              return (
                <TableRow hover tabIndex={-1} key={`table-row-${rowIndex}`}>
                  <TableCell padding="normal" className="td-checkbox">
                    <Checkbox
                      checked={selected.indexOf(row.id) !== -1}
                      color="primary"
                      value={selected.includes(rowIndex) ? true : false}
                      onChange={() => toggleSelect(row.id)}
                      inputProps={{
                        "aria-labelledby": `enhanced-table-checkbox-${rowIndex}`,
                      }}
                    />
                  </TableCell>

                  {headCells.map((col, colIndex) => (
                    <TableCell
                      className={`${colIndex === 0 ? "pd-checkbox" : ""} ${
                        col.key === "action" ? "td-checkbox" : ""
                      }`}
                      align="left"
                      key={`-row_${rowIndex}-col_${colIndex}`}
                    >
                      {row[col.key]}
                    </TableCell>
                  ))}

                  {row?.actions && (
                    <TableCell className="td-checkbox">
                      <DropDownWrapper
                        className="more-actions"
                        action={
                          <IconButton
                            className="more-action-btn"
                            aria-label="actions"
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        }
                      >
                        {row?.actions}
                      </DropDownWrapper>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {isPage && (
          <TablePagination
            meta={{...meta, rowCount: tableData.length}}
            page={page}
            setPage={setPage}
          />
        )}
      </TableContainer>
    </Box>
  );
}
