import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import AdminLayout from "./templates/AdminLayout";
import HomeAdmin from "./pages/admin/home";
import Users from "./pages/admin/Users";
import Roles from "./pages/admin/Roles";
import EachUser from "./pages/admin/Users/EachUser";
import Transactions from "./pages/admin/Transactions";
import EachTransaction from "./pages/admin/Transactions/EachTransaction";
import AdminSettings from "./pages/admin/settings";
import Disputes from "./pages/admin/Disputes";
import Chat from "./pages/Chat";
import { useGetUser } from "hooks/getUserHook";
import AdminLogin from "pages/admin/Login";
import NotFound from "pages/404";
import { selectToken } from "store/slice/AuthSlice";
import { useSelector } from "react-redux";
import AdminChat from "pages/admin/AdminChat";

function AllRoutes() {
  const { user } = useGetUser();
  const token = useSelector(selectToken);
  const PrivateRoute = ({ children, type = "user" }) => {
    let location = useLocation();
    return (
      <>
        {user || token ? (
          children
        ) : (
          <Navigate
            to={`${type === "user" ? "" : "/admin"}/login?redirectTo=${
              location.pathname
            }`}
            state={{ from: location }}
          />
        )}
      </>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />

        <Route
          path="/chats"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<AdminLogin />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute type="admin">
              <AdminLayout />
            </PrivateRoute>
          }
        >
          <Route path="" element={<HomeAdmin />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="transactions/:id" element={<EachTransaction />} />
          <Route path="disputes" element={<Disputes />} />
          <Route path="users" element={<Users />} />
          <Route path="users/:id" element={<EachUser />} />
          <Route path="roles" element={<Roles />} />
          <Route path="settings" element={<AdminSettings />} />
          <Route
            path="disputes/:id"
            element={
              <PrivateRoute>
                <AdminChat />
              </PrivateRoute>
            }
          />
          {/* demo page: path would be /dashboard/demo */}
        </Route>

        {/* Other routes not using admin layout should stay here e.g:*/}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
export default AllRoutes;
