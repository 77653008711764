import {Button, Chip, Stack} from "@mui/material";
import {useState} from "react";
import TableComponent from "components/TableComponent";
import SortSelect from "components/TableComponent/SortSelect";
import {getComparator, stableSort, toCurrency} from "utils/utils";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import {status_key_pair} from "components/VerifyCard";
import moment from "moment";

function TransactionsTab({transactions}) {
  const [selectedMember, setSelectedMember] = useState([]);
  const [orderBy, setOrderBy] = useState("index");
  return (
    <div>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <div className="flex">
          <div className="item">
            <div className="marker success" />
            <span>
              {" "}
              (
              {
                transactions.filter(
                  el => el.status.toLowerCase() === "approved"
                ).length
              }
              ) Approved Transactions
            </span>
          </div>
          <div className="item">
            <div className="marker warning" />

            <span>
              {" "}
              (
              {
                transactions.filter(el => el.status.toLowerCase() === "pending")
                  .length
              }
              ) Pending Transactions
            </span>
          </div>
          <div className="item">
            <div className="marker error" />
            <span>
              (
              {
                transactions.filter(
                  el => el.status.toLowerCase() === "cancelled"
                ).length
              }
              ) Canceled Transactions
            </span>
          </div>
        </div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"flex-end"}
        >
          <SortSelect
            value={orderBy}
            onChange={e => setOrderBy(e.target.value)}
            name="feedback_type"
            selectOption={[
              {label: "Default", value: "index"},
              {label: "Payment Reference", value: "transaction_reference"},
              {label: "Buyer Email", value: "buyer_email"},
              "amount",
              "status",
              {label: "date", value: "created_at"},
            ]}
          />
          <Button variant="contained" startIcon={<OpenInNewOutlinedIcon />}>
            Export
          </Button>
        </Stack>
      </Stack>
      <TableComponent
        headCells={[
          {name: "Buyers’ email", key: "buyer_email"},
          {name: "Payment Reference", key: "transaction_reference"},
          {name: "Amount", key: "amount"},
          {name: "Status", key: "status"},
          {name: "Date created", key: "created_at"},
        ]}
        isPage={false}
        tableData={stableSort(transactions, getComparator("asc", orderBy)).map(
          (item, i) => ({
            buyer_email: item.buyer_email,
            transaction_reference: item.transaction_reference,
            amount: toCurrency(item.amount),
            status: (
              <Chip
                label={item.status}
                color={
                  item.status
                    ? status_key_pair[item.status.toLowerCase()]
                    : "yellowClr"
                }
              />
            ),
            created_at: moment(item.created_at).format("l"),
            id: item.id,
          })
        )}
        type="outline"
        selected={selectedMember}
        onSelect={selectedIds => setSelectedMember(selectedIds)}
      />
    </div>
  );
}

export default TransactionsTab;
