import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as Dispute } from "assets/icons/dispute.svg";
import EmptyResponse from "components/EmptyResponse";
const DisputeCard = ({ data }) => {
  return (
    <div className="pd_dispute_card">
      <p className="topic">Recent Disputes</p>
      {!data.length ? (
        <EmptyResponse message={"Empty response"} />
      ) : (
        data.map((item) => {
          return (
            <Link to="/login" key={item} className="eachDisp">
              <div className="left">
                <Dispute className="disputeIcon" />{" "}
                <p className="product">Bag</p>{" "}
              </div>
              <p className="time"> {moment(222222222).format("L")} </p>
            </Link>
          );
        })
      )}
    </div>
  );
};

export default DisputeCard;
