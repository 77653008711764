import {Button} from "@mui/material";
import {ReactComponent as Logo} from "assets/icons/logo.svg";
import {Link} from "react-router-dom";
import "./styles.scss";
import SentimentDissatisfiedSharpIcon from "@mui/icons-material/SentimentDissatisfiedSharp";
function NotFound() {
  return (
    <div className="not-found">
      <div className="inner">
        <Logo className="" />
        <div className="content">
          <h1>
            <SentimentDissatisfiedSharpIcon sx={{mb: 1}} color="primary" />
          </h1>
          <h1 className="title">Oops, page not found</h1>
          <br />
          <p>
            We are very sorry for the inconvenience, it looks like the page you
            are trying to access is broken or never existed.
          </p>
          <br />
          <Button
            className="go-home-btn"
            variant="contained"
            component={Link}
            to="/"
          >
            Go Home
          </Button>
        </div>
        <div className="footer">
          &copy; {new Date().getFullYear()} Archangel.ng
        </div>
      </div>
    </div>
  );
}

export default NotFound;
