import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut } from "../store/slice/AuthSlice";
import { toaster } from "utils/utils";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;
// const API_ENDPOINT = "https://paay-be.herokuapp.com/api/v1/";
const baseQuery = fetchBaseQuery({
  baseUrl: API_ENDPOINT,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // logout
    toaster(
      "error",
      "Looks like your session has expired, please login again."
    );
    setTimeout(() => {
      api.dispatch(logOut());
    }, 1000);
  }
  return result;
};

export const authApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "authApi",
  tagTypes: [
    "user",
    "link",
    "transaction",
    "wallet",
    "banks",
    "dashboard",
    "users",
    "admins",
    "dispute",
    "chat",
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/sign-in",
        method: "POST",
        body: credentials,
      }),
    }),
    signup: builder.mutation({
      query: (credentials) => ({
        url: "auth/sign-up",
        method: "POST",
        body: credentials,
      }),
    }),
    getUserDetails: builder.query({
      query: () => `user/details`,
      providesTags: ["user"],
    }),
    updateUserDetails: builder.mutation({
      query: (body) => ({
        url: `user/update-profile`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),

    initiateDispute: builder.mutation({
      query: ({ body, id }) => ({
        url: `user/initiate-dispute/${id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["dispute"],
    }),
    getUserDisputes: builder.query({
      query: () => `user/disputes`,
      providesTags: ["dispute"],
    }),
    sendUserChat: builder.mutation({
      query: ({ body, id }) => ({
        url: `user/dispute/${id}/send-message`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["chat"],
    }),

    getSingleUserDisputes: builder.query({
      query: ({ id }) => `user/disputes/${id}`,
      providesTags: ["chat"],
    }),

    addBankAccount: builder.mutation({
      query: (body) => ({
        url: `user/add-bank`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["banks"],
    }),

    updateUserPassword: builder.mutation({
      query: (body) => ({
        url: `user/change-password`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
    }),
    deleteUser: builder.mutation({
      query: (body) => ({
        url: `user/delete-account`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["banks"],
    }),
    getBanks: builder.query({
      query: () => `bank`,
      providesTags: ["banks"],
    }),
    getUserBanks: builder.query({
      query: () => `user/banks`,
      providesTags: ["banks"],
    }),
    resolveBank: builder.mutation({
      query: (body) => ({
        url: `bank/resolve`,
        method: "POST",
        body,
      }),
    }),
    deleteBank: builder.mutation({
      query: (id) => ({
        url: `user/delete-bank/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["banks"],
    }),
    verify: builder.mutation({
      query: (token) => ({
        url: `auth/verify/${token}`,
        method: "POST",
      }),
    }),
    resetEmail: builder.mutation({
      query: (credentials) => ({
        url: "auth/forgot-password",
        method: "POST",
        body: credentials,
      }),
    }),
    resetPwd: builder.mutation({
      query: ({ credentials, token }) => ({
        url: `auth/reset-password/${token}`,
        method: "POST",
        body: credentials,
      }),
    }),
    createLink: builder.mutation({
      query: (body) => ({
        url: `user/create-payment-link`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["link"],
    }),
    getLink: builder.query({
      query: () => `user/payment-links`,
      providesTags: ["link"],
    }),
    getEachLink: builder.query({
      query: (id) => `user/payment-links/${id}`,
      providesTags: ["link"],
    }),
    getTransaction: builder.query({
      query: () => `user/transactions`,
      providesTags: ["transaction"],
    }),
    getEachTransaction: builder.query({
      query: (id) => `user/transactions/${id}`,
      providesTags: ["transaction"],
    }),
    getWalletDetails: builder.query({
      query: () => `user/wallet`,
      providesTags: ["wallet"],
    }),
    withdraw: builder.mutation({
      query: (body) => ({
        url: "user/withdraw",
        method: "POST",
        body,
      }),
    }),
    // actions on cards
    updateTransaction: builder.mutation({
      query: ({ credentials, id, type }) => ({
        url: `user/update-transaction/${id}?type=${type}`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["link", "transaction"],
    }),
    cancelTransaction: builder.mutation({
      query: ({ id, type }) => ({
        url: `user/update-transaction/${id}?type=${type}`,
        method: "POST",
      }),
      invalidatesTags: ["link", "transaction"],
    }),

    // admin endpoints
    adminLogin: builder.mutation({
      query: (body) => ({
        url: `admin/sign-in`,
        method: "POST",
        body,
      }),
    }),
    getDashboard: builder.query({
      query: (year) => `admin/dashboard?year=${year}`,
      providesTags: ["dashboard"],
    }),
    getYears: builder.query({
      query: () => `admin/transactions-year`,
      providesTags: ["dashboard"],
    }),
    getAdminTransactions: builder.query({
      query: (page) => `admin/transactions?page=${page}&limit=15`,
      providesTags: ["transaction"],
      transformResponse: (data) => data.data,
    }),
    getAdminTransactionsDownload: builder.query({
      query: () => `admin/transactions?limit=-1`,
      providesTags: ["transaction"],
      transformResponse: (data) => data.data,
    }),
    getSingleTransaction: builder.query({
      query: (id) => `admin/transactions/${id}`,
      providesTags: ["transaction"],
      transformResponse: (data) => data.data,
    }),
    getAdminUsers: builder.query({
      query: (page) => `admin/users?page=${page}&limit=15`,
      providesTags: ["users"],
      transformResponse: (data) => data.data,
    }),
    getAdminAllUsers: builder.query({
      query: () => `admin/users?page=1&limit=-1`,
      providesTags: ["users"],
      transformResponse: (data) => data.data,
    }),

    getAdminSingleUser: builder.query({
      query: (id) => `admin/users/${id}`,
      providesTags: ["users"],
      transformResponse: (data) => data.data,
    }),
    adminDeleteUser: builder.mutation({
      query: (credentials) => ({
        url: "auth/delete-temp",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["users"],
    }),
    adminSuspendUser: builder.mutation({
      query: ({ id, credentials }) => ({
        url: `admin/change-user-status/${id}`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["users"],
    }),
    getAdmins: builder.query({
      query: (page) => `admin/admins/?page=${page}`,
      providesTags: ["admins"],
      transformResponse: (data) => data.data,
    }),
    geRoles: builder.query({
      query: (page) => `admin/roles/?page=${page}`,
      providesTags: ["admins"],
      transformResponse: (data) => data.data,
    }),
    addRole: builder.mutation({
      query: (credentials) => ({
        url: "admin/add-role",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["admins"],
    }),
    delRole: builder.mutation({
      query: (id) => ({
        url: `admin/delete-role/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["admins"],
    }),
    addAdmin: builder.mutation({
      query: (credentials) => ({
        url: "admin/add-admin",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["admins"],
    }),
    delAdmin: builder.mutation({
      query: (id) => ({
        url: `admin/delete-admin/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["admins"],
    }),

    adminChangePassword: builder.mutation({
      query: (credentials) => ({
        url: "admin/change-password",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["admins"],
    }),
    adminChangeDetails: builder.mutation({
      query: (credentials) => ({
        url: "admin/update-profile",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["admins"],
    }),
    getAdminDetails: builder.query({
      query: () => `admin/details`,
      providesTags: ["admins"],
    }),
    getAdminDisputes: builder.query({
      query: (page) => `admin/disputes?page=${page}&limit=15`,
      providesTags: ["dispute"],
      transformResponse: (data) => data,
    }),
    getAdminSingleDisputes: builder.query({
      query: (id) => `admin/disputes/${id}`,
      providesTags: ["chat"],
      transformResponse: (data) => data,
    }),
    sendAdminChat: builder.mutation({
      query: ({ body, id }) => ({
        url: `admin/dispute/${id}/send-message`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["chat"],
    }),
    resolveDispute: builder.mutation({
      query: ({ body, id }) => ({
        url: `admin/disputes/${id}/resolve`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["chat"],
    }),
  }),
});
export const {
  useLoginMutation,
  useSignupMutation,
  useVerifyMutation,
  useResetPwdMutation,
  useResetEmailMutation,
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
  useCreateLinkMutation,
  useGetLinkQuery,
  useGetTransactionQuery,
  useDeleteUserMutation,
  useUpdateUserPasswordMutation,
  useUpdateTransactionMutation,
  useCancelTransactionMutation,
  useGetWalletDetailsQuery,
  useGetEachLinkQuery,
  useGetEachTransactionQuery,
  useGetBanksQuery,
  useAddBankAccountMutation,
  useResolveBankMutation,
  useGetUserBanksQuery,
  useWithdrawMutation,
  useDeleteBankMutation,
  useInitiateDisputeMutation,
  useGetUserDisputesQuery,
  useGetSingleUserDisputesQuery,
  useSendUserChatMutation,
  // admin endpoints
  useAdminLoginMutation,
  useGetDashboardQuery,
  useGetAdminTransactionsQuery,
  useGetAdminTransactionsDownloadQuery,
  useGetAdminUsersQuery,
  useGetAdminSingleUserQuery,
  useAdminDeleteUserMutation,
  useGetSingleTransactionQuery,
  useGetAdminsQuery,
  useGeRolesQuery,
  useAddAdminMutation,
  useAddRoleMutation,
  useAdminChangePasswordMutation,
  useAdminChangeDetailsMutation,
  useAdminSuspendUserMutation,
  useGetAdminDetailsQuery,
  useGetAdminDisputesQuery,
  useGetAdminSingleDisputesQuery,
  useSendAdminChatMutation,
  useResolveDisputeMutation,
  useDelAdminMutation,
  useDelRoleMutation,
  useGetYearsQuery,
  useGetAdminAllUsersQuery,
} = authApi;
