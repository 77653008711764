import { Chip, Link as LinkStyle, Stack } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import TableComponent from "components/TableComponent";
import SortSelect from "components/TableComponent/SortSelect";
import { getComparator, stableSort } from "utils/utils";
// import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { FormProvider, useForm } from "react-hook-form";
import InputField from "components/Input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  useGetAdminTransactionsDownloadQuery,
  useGetAdminTransactionsQuery,
} from "services/api";
import { status_key_pair } from "components/VerifyCard";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Transactions = () => {
  const [selectedMember, setSelectedMember] = useState([]);
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } =
    useGetAdminTransactionsQuery(page);
  const { data: download } = useGetAdminTransactionsDownloadQuery(page);
  const [orderBy, setOrderBy] = useState("index");
  const methods = useForm();
  return (
    <div className="pd_home">
      <h2 className="pg-title">Transactions</h2>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <FormProvider {...methods}>
          <form>
            <InputField
              className={"search-input"}
              name="search"
              type={"search"}
              placeholder="Search by store name or keyword..."
              iconPosition="left"
              iconPlaceholder={
                <SearchOutlinedIcon color="textClr" fontSize="large" />
              }
            />
          </form>
        </FormProvider>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"flex-end"}
        >
          <SortSelect
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            name="feedback_type"
            selectOption={[
              { label: "Default", value: "index" },
              { label: "Seller email", value: "seller_email" },
              { label: "Buyer email", value: "buyer_email" },
              { label: "Payment Link", value: "payment_link" },
              { label: "Date", value: "created_at" },
              "id",
              "status",
            ]}
          />
          {/* <Button variant="contained" startIcon={<OpenInNewOutlinedIcon />}>
            Export
          </Button> */}
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button btn btn-success"
            table="table-to-xls"
            filename="Transactions"
            sheet="tablexls"
            buttonText="Export"
          />
        </Stack>
      </Stack>
      <div className="table_download" style={{ display: "none" }}>
        <table id="table-to-xls">
          <thead>
            <th>Transaction ID</th>
            <th>Payment link</th>
            <th>Seller's Email</th>
            <th>Status</th>
            <th>Date created</th>
          </thead>

          <tbody>
            {download &&
              download?.transactions.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{`${window.location.origin}/link/${item.payment_link.payment_link}`}</td>
                    <td>{item.seller_email}</td>
                    <td> {item.status} </td>
                    <td>{moment(item.created_at).format("ll")}</td>
                  </tr>
                );
              })}
            <tr></tr>
          </tbody>
        </table>
      </div>
      <TableComponent
        isLoading={isLoading}
        isError={isError}
        error={error}
        page={page}
        setPage={setPage}
        meta={{
          totalPages: data?.totalPages,
          currentPage: data?.currentPage,
          totalCount: data?.totalTransactions,
        }}
        headCells={[
          { name: "Transaction ID", key: "id" },
          { name: "Payment link", key: "payment_link" },
          { name: "Seller's Email", key: "seller_email" },
          { name: "Status", key: "status" },
          { name: "Buyers’ email", key: "buyer_email" },
          { name: "Date created", key: "created_at" },
        ]}
        tableData={stableSort(
          data?.transactions || [],
          getComparator("asc", orderBy)
        ).map((item, i) => ({
          seller_email: item.seller_email,
          buyer_email: item.buyer_email,
          payment_link: (
            <LinkStyle
              component={Link}
              to={`/link/${item.payment_link.payment_link}`}
            >{`${window.location.origin}/link/${item.payment_link.payment_link}`}</LinkStyle>
          ),
          status: (
            <Chip
              label={item.status}
              color={status_key_pair[item.status.toLowerCase()]}
            />
          ),
          actions: (
            <>
              <Link to={`${item.id}`} className="btn-noBg">
                View Transaction
              </Link>
            </>
          ),
          created_at: moment(item.created_at).format("ll"),
          id: item.id,
        }))}
        selected={selectedMember}
        onSelect={(selectedIds) => setSelectedMember(selectedIds)}
      />
    </div>
  );
};

export default Transactions;
