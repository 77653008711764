import {useEffect, useRef, useState} from "react";
import ChatBox from "components/ChatBox";
import LoadingHead from "components/LoaderHead/loaderhead";
import MessageBox from "components/MessageBox";
// import {ReactComponent as Fileicon} from "assets/icons/fileIcon.svg";

import "./style.scss";
import NoProduct from "components/NoProduct";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import Skeleton from "@mui/material/Skeleton";
import {CardHeader} from "@mui/material";
import MainLayout from "templates/MainLayout";
import {
  useGetSingleUserDisputesQuery,
  useGetUserDisputesQuery,
} from "services/api";
import {useGetUser} from "hooks/getUserHook";
import Loader from "components/Loader";
import ErrorMsg from "components/ErrorMsg";
import io from "socket.io-client";
const Chat = () => {
  const [activeMsg, setActiveMsg] = useState("");
  const [skip, setSkip] = useState(true);
  const [message, setMessage] = useState([]);
  const {user} = useGetUser();

  const {data = null, isLoading, isError, error} = useGetUserDisputesQuery();

  // get single user chat

  const {
    data: chat = null,
    isLoading: load,
    isError: isSingleChatError,
  } = useGetSingleUserDisputesQuery(
    {
      id: activeMsg,
    },
    {
      skip,
    }
  );

  const socketRef = useRef(null);
  useEffect(() => {
    if (activeMsg) {
      socketRef.current = io("https://paay-be.herokuapp.com", {
        query: {conversationId: activeMsg},
        transports: ["websocket"],
      });

      socketRef.current.on("connect", () => {});

      socketRef.current.on(`dispute_${activeMsg}`, newMsg => {
        setMessage(prev => {
          return [
            ...prev,
            {
              created_at: newMsg.created_at,
              files: newMsg.files,
              id: newMsg.id,
              isAdmin: newMsg.isAdmin,
              message: newMsg.message,
              sender_id: newMsg.sender_id,
              sender_name: newMsg.sender_name,
              updated_at: newMsg.updated_at,
            },
          ];
        });
      });
      return () => {
        socketRef.current.disconnect();
      };
    }
  }, [activeMsg]);

  useEffect(() => {
    // setMessage(chat.data[0].messages);
    if (chat) {
      setMessage(chat.data.messages);
    }
  }, [chat]);

  const [realConv, setRealConv] = useState([]);
  useEffect(() => {
    if (data) {
      setRealConv(data.data);
    }
  }, [data]);
  return (
    <MainLayout>
      <section className="chat">
        <div className="pd-chat">
          <LoadingHead status={load} />
          {isError && <ErrorMsg error={error} />}
          {isLoading ? (
            <Loader />
          ) : (
            <div className="whiteContainer">
              <div className={`messageList ${activeMsg ? "active" : ""}`}>
                <div className="messageTop">
                  <div className="new">
                    <p>Conversations</p>
                  </div>

                  <input type="text" placeholder="Search" className="search" />
                  <p className="recent">Recent</p>
                </div>
                <div className="msgDiv">
                  {!isLoading ? (
                    realConv ? (
                      !realConv.length ? (
                        <NoProduct msg="No Chats Found...">
                          <SpeakerNotesOffIcon />
                        </NoProduct>
                      ) : (
                        realConv.map(item => {
                          return (
                            <MessageBox
                              key={item.id}
                              id={item.id}
                              latestMessage={
                                item.messages &&
                                item.messages[
                                  Number(item.messages.length) - 1
                                ] &&
                                item.messages[Number(item.messages.length) - 1]
                                  .message
                              }
                              time={item.updated_at}
                              setActive={setActiveMsg}
                              active={item.id === activeMsg}
                              member={
                                item.buyer_email !== user.email
                                  ? item.buyer
                                  : item.seller
                              }
                              // setId={setId}
                              skipper={() => {
                                setSkip(false);
                              }}
                            />
                          );
                        })
                      )
                    ) : (
                      <NoProduct msg="No Chats Yet...">
                        <SpeakerNotesOffIcon />
                      </NoProduct>
                    )
                  ) : (
                    // <p>Loading</p>
                    <>
                      {Array(5)
                        .fill("")
                        .map((_, i) => (
                          <CardHeader
                            key={`skeleton_${i}`}
                            avatar={
                              <Skeleton
                                animation="wave"
                                variant="circular"
                                width={40}
                                height={40}
                              />
                            }
                            title={
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="80%"
                                style={{marginBottom: 6}}
                              />
                            }
                            subheader={
                              <Skeleton
                                animation="wave"
                                height={10}
                                width="40%"
                              />
                            }
                          />
                        ))}
                    </>
                  )}
                </div>
              </div>
              <div
                className={`chatBoxDiv notadmin ${activeMsg ? "active" : ""}`}
              >
                <ChatBox
                  setActive={setActiveMsg}
                  active={activeMsg}
                  isError={isSingleChatError || isError}
                  currentMsg={{member: chat && chat.data, isActive: true}}
                  // headmember={
                  //   item.buyer_email === user.email ? item.buyer : item.sender
                  // }
                  // members: [
                  //   {
                  //     firstName: "Ajayi",
                  //     lastName: "Emmanuel",
                  //     _id: "1232135",
                  //   },
                  //   {
                  //     firstName: "Raji",
                  //     lastName: "Mustapha",
                  //     _id: "3141r5",
                  //   },
                  // ],

                  messages={message}
                  loading={load}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </MainLayout>
  );
};

export default Chat;
