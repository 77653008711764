import {getComparator, stableSort} from "utils/utils";
import TableComponent from "components/TableComponent";
import {useState} from "react";
import {useGetAdminsQuery} from "services/api";
import moment from "moment";
import {Avatar, IconButton, Stack} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {toaster} from "utils/utils";

import {useDelAdminMutation} from "services/api";

function AdminsTable({orderBy}) {
  const [selectedMember, setSelectedMember] = useState([]);
  const [page, setPage] = useState(1);
  const {data, isLoading, isError, error} = useGetAdminsQuery(page);
  const [delLoading, setDelLoading] = useState("");
  const [delAdmin] = useDelAdminMutation();

  const deleteHandler = async id => {
    setDelLoading(id);
    try {
      await delAdmin(id).unwrap();
    } catch (err) {
      if (err.status === "FETCH_ERROR") {
        toaster(
          "error",
          "Something went wrong, please check your internet connection and try again"
        );
      } else {
        toaster("error", err.data.message);
      }
    } finally {
      setDelLoading("");
    }
  };
  return (
    <TableComponent
      headCells={[
        {
          name: "Full name",
          key: "full_name",
        },
        {
          name: "Email",
          key: "email",
        },
        {
          name: "Role",
          key: "role",
        },
        {
          name: "Date created",
          key: "created_at",
        },
        {
          name: "",
          key: "action",
        },
      ]}
      isLoading={isLoading}
      isError={isError}
      error={error}
      page={page}
      setPage={setPage}
      meta={{
        totalPages: data?.totalPages,
        currentPage: data?.currentPage,
        totalCount: data?.totalAdmins,
      }}
      tableData={stableSort(data?.admins, getComparator("asc", orderBy)).map(
        item => ({
          full_name: (
            <Stack direction="row" spacing={1} alignItems="center">
              <Avatar
                src={item.profile_image}
                sx={{
                  width: "1.8rem",
                  height: "1.8rem",
                }}
              />
              <span>{item.full_name}</span>
            </Stack>
          ),
          role: item.role.title,
          email: item.email,
          created_at: moment(item.created_at).format("ll"),
          id: item.id,
          action: (
            <IconButton onClick={() => deleteHandler(item.id)}>
              {delLoading === item.id ? (
                <div
                  style={{
                    borderTop: "2px solid var(--primary)",
                    borderLeft: "2px solid var(--primary)",
                    borderBottom: "2px solid var(--primary)",
                  }}
                  className="spin"
                ></div>
              ) : (
                <DeleteOutlineOutlinedIcon color="error" />
              )}
            </IconButton>
          ),
        })
      )}
      selected={selectedMember}
      onSelect={selectedIds => setSelectedMember(selectedIds)}
    />
  );
}

export default AdminsTable;
