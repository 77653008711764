import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";

export default function EnhancedTableHead(props) {
  const {onSelectAllClick, numSelected, rowCount, headCells} = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{minWidth: "max-content"}}
          align={"left"}
          className="td-checkbox"
          padding={"checkbox"}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all rows",
            }}
          />
        </TableCell>
        {headCells.map((headCell, i) => (
          <TableCell
            className={`${i === 0 ? "pd-checkbox" : ""}`}
            key={headCell.key}
            align={"left"}
            padding={"normal"}
          >
            {headCell.name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
