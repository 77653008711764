import "./style.scss";
import mobileLogo from "assets/icons/logo.png";

const AuthLayout = ({ children, title, subTitle, sucMsg }) => {
  return (
    <div className="authLayout">
      <img src={mobileLogo} alt="Archangel logo" className="logo" />
      <p className="title">{title}</p>
      {subTitle && <p className="titleSub">{subTitle}</p>}
      {sucMsg && <p className="sucMsg">{sucMsg}.</p>}
      <div className={`midBox ${sucMsg ? "ext" : ""}`}>{children}</div>
    </div>
  );
};

export default AuthLayout;
