import {
  ListItem,
  Typography,
  List,
  Avatar,
  Button,
  IconButton,
} from "@mui/material";
import { Link, useMatch } from "react-router-dom";
// import { ReactComponent as Logo } from "assets/icons/logo.svg";
import DropDownWrapper from "../DropDownWrapper";
import "./style.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogOutIcon } from "assets/icons/logout.svg";
import { logOut } from "store/slice/AuthSlice";
import { useGetUser } from "hooks/getUserHook";
import mobileLogo from "assets/icons/logo.png";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

export const CustomLink = ({ children, to, ...props }) => {
  let match = useMatch(to !== "" ? `/` + to + "/*" : `/` + to);
  return (
    <ListItem
      className={`nav_link ${match ? "active_link" : ""}`}
      to={"/" + to}
      {...props}
      component={Link}
    >
      <Typography textAlign="center">{children}</Typography>
    </ListItem>
  );
};

function NavBar() {
  const [showNav, setshowNav] = useState(false);
  const { user } = useGetUser();
  return (
    <nav className="nav_bar">
      <div className="inner">
        <Link to="/">
          {/* <Logo className="logo" /> */}
          <img src={mobileLogo} alt="Paay logo" className="logo" />
        </Link>
        <img src={mobileLogo} alt="Paay logo" className="logo-mb" />
        <div className="nav_links_wrap">
          <List className={`nav_links ${showNav ? "show-nav" : ""}`}>
            <CustomLink to={""}>Home</CustomLink>
            <CustomLink to={"wallet"}>Wallet</CustomLink>
            <CustomLink to={"chat"}>Chat</CustomLink>
            <Button
              sx={{
                mx: "1rem",
                mt: "0.4rem",
                display: {
                  xs: "inline-flex",
                  sm: "none",
                },
              }}
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              component={Link}
              to="/create-link"
            >
              Create Link
            </Button>
          </List>

          <DropDownWrapper
            action={
              <Button
                startIcon={
                  <Avatar
                    className="avatar"
                    alt={user?.full_name}
                    src={user?.profile_image}
                  />
                }
                endIcon={<ArrowDropDownIcon />}
                color="textClr"
                sx={{ p: "0.5rem" }}
                className="profile-action"
              >
                <p>Account</p>
              </Button>
            }
            className="profile-drop-down"
          >
            <Button
              component={Link}
              to="/account-settings"
              color="textLightClr"
              startIcon={<PersonIcon />}
            >
              Account settings
            </Button>
            {/* <Button color="textLightClr" startIcon={<LinkIcon />}>
              Share your link
            </Button>
            <Button color="textLightClr" startIcon={<WalletIcon />}>
              Payments
            </Button>
            <Button color="textLightClr" startIcon={<InfoOutlinedIcon />}>
              Help
            </Button> */}
            <div className="hr" />
            <Button onClick={logOut} color="redClr" startIcon={<LogOutIcon />}>
              Logout
            </Button>
          </DropDownWrapper>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => setshowNav(!showNav)}
            className="menu-btn"
          >
            {showNav ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
