import { ReactComponent as User } from "assets/icons/user.svg";
import { ReactComponent as Tran } from "assets/icons/tran.svg";
import { ReactComponent as Pay } from "assets/icons/pay.svg";
import { ReactComponent as Dispute } from "assets/icons/dispute.svg";
import EachBox from "./widget/eachBox";
import "./style.scss";
import DisputeCard from "./widget/disputeCard";
import Chart from "react-apexcharts";
import RecentActivities from "./widget/recentActivities";
import { useGetDashboardQuery, useGetYearsQuery } from "services/api";
import ErrorMsg from "components/ErrorMsg";
import Loader from "components/Loader";
import { useState } from "react";
const HomeAdmin = () => {
  const [year, setYear] = useState("2022");
  const { data = null, isLoading, isError, error } = useGetDashboardQuery(year);

  const { data: years = null } = useGetYearsQuery();
  console.log(years);
  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },

    grid: {
      borderColor: "#E2E8F0",
      strokeDashArray: 5,
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "June",
        "July",
        "Sept",
        "Aug",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  const series = [
    {
      name: "series-1",
      data: data
        ? data.data.transactionsOverview.datasets
        : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ];

  return (
    <>
      {isLoading && <Loader />}
      {isError && <ErrorMsg error={error} />}
      {data && (
        <div className="pd_home">
          <div className="summary_box">
            <EachBox
              icon={<Tran />}
              title={"Total Transactions"}
              amount={data.data.totalTransactions}
            />
            <EachBox
              border={"right"}
              icon={<Pay />}
              amount={data.data.totalPaymentLinks}
              title={"Total PaymentLinks"}
            />
            <EachBox
              border={"left"}
              icon={<Dispute />}
              amount={data.data.totalDisputes}
              title={"Total Disputes"}
            />
            <EachBox
              icon={<User />}
              title={"Total Users"}
              amount={data.data.totalUsers}
            />
          </div>

          <div className="chatSide">
            <div className="barChart">
              <div className="topicFlex">
                <p className="about">Transaction Overview</p>
                <select
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                  className="selectyear"
                  name=""
                  id=""
                >
                  {years &&
                    years.data.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                </select>
              </div>
              <Chart
                options={options}
                series={series}
                type="line"
                height={"350"}
              />
            </div>
            <div className="list">
              <DisputeCard data={data ? data.data.recentDisputes : []} />
            </div>
          </div>

          <div className="tableSide">
            <p className="title">Recent Activities</p>
            <RecentActivities data={data ? data.data.recentActivities : []} />
          </div>
        </div>
      )}
    </>
  );
};

export default HomeAdmin;
