import "./style.scss";
const EmptyResponse = ({
  image = "/images/emptyBox.svg",
  message,
  btn,
  ...props
}) => {
  return (
    <div {...props} className="empty-response-pg">
      <img src={image} alt="no result" />
      <p>{message}</p>
      {btn && btn}
    </div>
  );
};

export default EmptyResponse;
