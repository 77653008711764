import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {Pagination, PaginationItem, Stack} from "@mui/material";
import "./style.scss";
function TablePagination({
  meta: {totalPages, currentPage, rowCount, totalCount},
  page,
  setPage,
}) {
  const Previous = () => (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{p: "0 0.5rem"}}
    >
      <ArrowBackIcon fontSize="small" /> <span>Prev</span>
    </Stack>
  );
  const Next = () => (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{p: "0 0.5rem"}}
    >
      <span>Next</span> <ArrowForwardIcon fontSize="small" />
    </Stack>
  );

  return (
    <>
      <div className="pagination-wrap">
        <p>
          Showing {rowCount} of {totalCount}
        </p>
        <Pagination
          color="primary"
          count={totalPages}
          defaultPage={currentPage}
          page={page}
          onChange={(e, val) => setPage(val)}
          renderItem={item => (
            <PaginationItem
              components={{
                previous: Previous,
                next: Next,
              }}
              {...item}
            />
          )}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </>
  );
}

export default TablePagination;
