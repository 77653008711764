import { getComparator, stableSort } from "utils/utils";
import TableComponent from "components/TableComponent";
import { useState } from "react";
import { useGeRolesQuery } from "services/api";
import moment from "moment";
function RolesTable({ orderBy }) {
  const [selectedMember, setSelectedMember] = useState([]);
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, error } = useGeRolesQuery(page);

  return (
    <TableComponent
      headCells={[
        {
          name: "Title",
          key: "title",
        },
        {
          name: "Description",
          key: "description",
        },
        {
          name: "Assigned admins",
          key: "users",
        },
        {
          name: "Date created",
          key: "created_at",
        },
        {
          name: "",
          key: "action",
        },
      ]}
      isLoading={isLoading}
      isError={isError}
      error={error}
      page={page}
      setPage={setPage}
      meta={{
        totalPages: data?.totalPages,
        currentPage: data?.currentPage,
        totalCount: data?.totalRoles,
      }}
      tableData={stableSort(data?.roles, getComparator("asc", orderBy)).map(
        (item, i) => ({
          title: item.title,
          description: item.description,
          users: item.admins.length,
          created_at: moment(item.created_at).format("ll"),
          id: item.id,
        })
      )}
      selected={selectedMember}
      onSelect={(selectedIds) => setSelectedMember(selectedIds)}
    />
  );
}

export default RolesTable;
