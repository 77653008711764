import {Button, Chip, IconButton, Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {useGetSingleTransactionQuery} from "services/api";
import {formatCurrency} from "utils/utils";
import PaperBackBtn from "../../../templates/backBtnPaper/index.";
import useCopy from "hooks/useCopy";
import CheckIcon from "@mui/icons-material/Check";
import {ReactComponent as LinkIcon} from "assets/icons/Link2.svg";
import Loader from "components/Loader";
import ErrorMsg from "components/ErrorMsg";
import {status_key_pair} from "components/VerifyCard";

function EachTransaction() {
  const {id} = useParams();
  const {data, isLoading, error, isError} = useGetSingleTransactionQuery(id);

  const {copied, copyAction} = useCopy(
    data
      ? `${window.location.origin}/link/${data?.payment_link?.payment_link}`
      : ""
  );
  if (isLoading) return <Loader />;
  if (isError) return <ErrorMsg error={error} />;
  return (
    <PaperBackBtn>
      <div style={{margin: 0, padding: 0}} className="payment-link admin">
        <p>Transaction Status</p>
        {data && (
          <>
            <Chip
              label={data.status}
              sx={{p: "0.5rem 1rem", mt: 1, mb: 3}}
              color={status_key_pair[data.status.toLowerCase()]}
            />
            <div className="details-wrap">
              <div className="details">
                <p className="title">Seller</p>
                <div
                  className="text"
                  href="mailto:Odunusinaderibigbe@gmail.com"
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <span>{data.seller_email}</span>
                  </Stack>
                </div>
              </div>
              <div className="details">
                <p className="title">Buyer</p>
                <Stack direction="row" spacing={1} alignItems="center">
                  <span>{data.buyer_email}</span>
                </Stack>
              </div>
              <div className="details w-full">
                <p className="title">Payment link</p>
                <p className="text">
                  {`${window.location.origin}/link/${data.payment_link.payment_link}`}
                  <IconButton
                    color={copied ? "greenClr" : "primary"}
                    onClick={() => copyAction()}
                    sx={{ml: 1}}
                  >
                    {copied ? <CheckIcon fontSize="small" /> : <LinkIcon />}
                  </IconButton>
                </p>
              </div>
              <div className="details">
                <p className="title">Product title</p>
                <p className="text">{data.payment_link.product_title}</p>
              </div>
              <div className="details">
                <p className="title">Amount</p>
                <p className="text">{formatCurrency(data.amount)}</p>
              </div>
            </div>
            <div className="details w-full">
              <p className="title">Product Description</p>
              <p className="text">{data.payment_link.description}</p>
            </div>
          </>
        )}

        <br />
        <Button variant="contained">Intiate dispute</Button>
      </div>
    </PaperBackBtn>
  );
}

export default EachTransaction;
