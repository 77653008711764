import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./style.scss";
const PaperBackBtn = ({children, ...props}) => {
  const navigate = useNavigate();

  return (
    <div {...props} className="pd_paper">
      <Button
        startIcon={<ArrowBackIcon />}
        color="textClr"
        onClick={() => {
          navigate(-1);
        }}
      >
        Back
      </Button>
      <br />
      <div style={{...props.style}} className="paperSide">
        {children}
      </div>
    </div>
  );
};

export default PaperBackBtn;
