import {Stack} from "@mui/material";

function TabsHeader({tabs, activeTab, setActiveTab}) {
  return (
    <Stack direction="row" spacing={2} my={3}>
      {tabs.map((tab, index) => (
        <span
          key={`tab-${index}`}
          className={`status_chip ${activeTab === tab ? "active" : ""}`}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </span>
      ))}
    </Stack>
  );
}

export default TabsHeader;
