import {useState} from "react";
import "./styles.scss";
import {Avatar, Button, CircularProgress} from "@mui/material";

const DpUpload = ({id, name, imgUrl, setImg, isLoading}) => {
  const [isSelected, setisSelected] = useState(false);
  const [localImgUrl, setLocalImgUrl] = useState("");
  const [error] = useState("");

  return (
    <div className={"uploadFile"}>
      <div className="avatar">
        <Avatar
          className={"img"}
          src={imgUrl || localImgUrl}
          alt={"Profile Picture"}
        />
        {isLoading && (
          <CircularProgress className="loader" size={20} color="white" />
        )}
      </div>
      <div className="right">
        <Button
          component="label"
          variant="outlined"
          color="primary"
          className={`${isSelected && "pic-selected"}`}
          htmlFor={id || name}
        >
          <input
            type="file"
            name={name}
            id={id || name}
            onChange={e => {
              setImg(e.target.files[0]);
              const reader = new FileReader();
              if (e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
                reader.addEventListener(
                  "load",
                  function () {
                    setLocalImgUrl(reader.result);
                  },
                  false
                );
              }
            }}
            onClick={() => setisSelected(false)}
            hidden
            accept="image/png, image/gif, image/jpeg"
          />
          Upload Picture
        </Button>
        <p className="clr-textClr">JPG, GIF or PNG. Max size of 5MB.</p>
      </div>
      {error && <div className="input-err-msg">{error}</div>}
    </div>
  );
};
export default DpUpload;
